import { z as zod } from "zod";

export const UpdateDetailsFormSchemas = zod
  .object({
    token: zod.string(),
    firstName: zod.string().min(1, "First name is required").max(30),
    lastName: zod.string().min(1, "Last name is required").max(30),
    mobileNumber: zod.string().min(1, "Phone or Mobile is required").max(13),
    // phoneNumber: zod.string().max(13),
    preferredStore: zod.string().min(1, "Preferred store is required"),
    emailAddress: zod
      .string()
      .min(1, "Email is required")
      .email("The Email Address field is not a valid e-mail address."),
    birthday: zod.string().optional(),
    password: zod
      .string()
      .min(1, "Password is required")
      .min(6, "Please enter at least 6 characters."),
    confirmPassword: zod
      .string()
      .min(1, "Please confirm password")
      .min(6, "Please enter at least 6 characters."),
    IsNewsUpdatesEmailsEnabled: zod.boolean().optional(),
    IsTextEnabled: zod.boolean().optional(),
    IsBdcTermsAccepted: zod.coerce.boolean().refine((bool) => bool == true, {
      message: "Please Accept Membership Terms and Conditions",
    }),
    IsTermsAccepted: zod.coerce.boolean().refine((bool) => bool == true, {
      message: "Please Accept Terms and Conditions",
    }),
  })
  .refine(
    (values) => {
      return values.password === values.confirmPassword;
    },
    {
      message: "Passwords must match!",
      path: ["confirmPassword"],
    }
  );

export const UpdateDetailsAPISchemas = zod.object({
  token: zod.string(),
  emailAddress: zod.string(),
  password: zod.string(),
  firstName: zod.string(),
  lastName: zod.string(),
  mobileNumber: zod.string(),
  birthday: zod.string().optional(),
  flEmail: zod.number().optional(),
  flSMS: zod.number().optional(),
  preferredStore: zod.string(),
});
