import React, { useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import "./Form.css";
import { useUpdateDetails } from "./useUpdateDetails";
import { useMemberDetailsUpdateForm } from "./useUpdateDetailsForm";

const STORES = [
  {
    id: 20516323,
    name: "Paper Plus Ashburton",
  },
  {
    id: 20516284,
    name: "Paper Plus Auckland Hospital",
  },
  {
    id: 20516137,
    name: "Paper Plus Barrington",
  },
  {
    id: 20516330,
    name: "Paper Plus Bethlehem",
  },
  {
    id: 20516156,
    name: "Paper Plus Birkenhead",
  },
  {
    id: 20516148,
    name: "Paper Plus Blenheim",
  },
  {
    id: 20516157,
    name: "Paper Plus Browns Bay",
  },
  {
    id: 103023633,
    name: "Paper Plus Cambridge",
  },
  {
    id: 20516139,
    name: "Paper Plus Carterton",
  },
  {
    id: 20516240,
    name: "Paper Plus Coastlands",
  },
  {
    id: 20516324,
    name: "Paper Plus Cromwell",
  },
  {
    id: 113871109,
    name: "Paper Plus Devonport",
  },
  {
    id: 20516175,
    name: "Paper Plus Dunedin",
  },
  {
    id: 20516158,
    name: "Paper Plus Eastridge",
  },
  {
    id: 20516225,
    name: "Paper Plus Feilding",
  },
  {
    id: 20516247,
    name: "Paper Plus Ferrymead",
  },
  {
    id: 20516292,
    name: "Paper Plus Gisborne",
  },
  {
    id: 116077447,
    name: "Paper Plus Glenfield Mall",
  },
  {
    id: 20516176,
    name: "Paper Plus Gore",
  },
  {
    id: 20516248,
    name: "Paper Plus Greymouth",
  },
  {
    id: 20516287,
    name: "Paper Plus Hamilton",
  },
  {
    id: 20516255,
    name: "Paper Plus Hastings",
  },
  {
    id: 20516227,
    name: "Paper Plus Hawera",
  },
  {
    id: 20516186,
    name: "Paper Plus Helensville",
  },
  {
    id: 20516183,
    name: "Paper Plus Highland Park",
  },
  {
    id: 20516187,
    name: "Paper Plus Hokitika",
  },
  {
    id: 20516170,
    name: "Paper Plus Hornby",
  },
  {
    id: 20516256,
    name: "Paper Plus Howick",
  },
  {
    id: 20516177,
    name: "Paper Plus Invercargill",
  },
  {
    id: 20516191,
    name: "Paper Plus Kaikoura",
  },
  {
    id: 20516193,
    name: "Paper Plus Katikati",
  },
  {
    id: 20516151,
    name: "Paper Plus Kerikeri",
  },
  {
    id: 20516242,
    name: "Paper Plus Kilbirnie",
  },
  {
    id: 20516244,
    name: "Paper Plus Levin",
  },
  {
    id: 20516245,
    name: "Paper Plus Lower Hutt",
  },
  {
    id: 20516289,
    name: "Paper Plus Masterton",
  },
  {
    id: 20516228,
    name: "Paper Plus Matamata",
  },
  {
    id: 20516162,
    name: "Paper Plus Meadowbank",
  },
  {
    id: 20516271,
    name: "Paper Plus Morrinsville",
  },
  {
    id: 20516332,
    name: "Paper Plus Mosgiel",
  },
  {
    id: 20516154,
    name: "Paper Plus Motueka",
  },
  {
    id: 20516200,
    name: "Paper Plus Mt Maunganui",
  },
  {
    id: 20516317,
    name: "Paper Plus Nelson",
  },
  {
    id: 20516294,
    name: "Paper Plus New Brighton",
  },
  {
    id: 20516260,
    name: "Paper Plus North City",
  },
  {
    id: 20516178,
    name: "Paper Plus Oamaru",
  },
  {
    id: 20516231,
    name: "Paper Plus Opotiki",
  },
  {
    id: 20516152,
    name: "Paper Plus Orewa",
  },
  {
    id: 20516261,
    name: "Paper Plus Otorohanga",
  },
  {
    id: 20516333,
    name: "Paper Plus Palmerston North",
  },
  {
    id: 20516208,
    name: "Paper Plus Papamoa",
  },
  {
    id: 20516164,
    name: "Paper Plus Parnell",
  },
  {
    id: 20516218,
    name: "Paper Plus Ponsonby",
  },
  {
    id: 20516277,
    name: "Paper Plus Pukekohe",
  },
  {
    id: 20516138,
    name: "Paper Plus Queenstown Airport",
  },
  {
    id: 20516219,
    name: "Paper Plus Remuera",
  },
  {
    id: 20516291,
    name: "Paper Plus Richmond",
  },
  {
    id: 20516279,
    name: "Paper Plus Rotorua Central",
  },
  {
    id: 20516185,
    name: "Paper Plus Select Havelock North",
  },
  {
    id: 20516190,
    name: "Paper Plus Select Kaiapoi",
  },
  {
    id: 20516192,
    name: "Paper Plus Select Karori",
  },
  {
    id: 20516209,
    name: "Paper Plus Select Petone",
  },
  {
    id: 20516221,
    name: "Paper Plus Select Sunnynook",
  },
  {
    id: 20516215,
    name: "Paper Plus Select Taihape",
  },
  {
    id: 20516300,
    name: "Paper Plus Select Waiheke",
  },
  {
    id: 20516321,
    name: "Paper Plus Select Waiuku",
  },
  {
    id: 20516233,
    name: "Paper Plus Stratford",
  },
  {
    id: 20516322,
    name: "Paper Plus Support Office",
  },
  {
    id: 20516216,
    name: "Paper Plus Takaka",
  },
  {
    id: 108567293,
    name: "Paper Plus Takapuna",
  },
  {
    id: 20516234,
    name: "Paper Plus Taradale",
  },
  {
    id: 20516320,
    name: "Paper Plus Taumarunui",
  },
  {
    id: 20516235,
    name: "Paper Plus Taupo",
  },
  {
    id: 20516184,
    name: "Paper Plus Te Anau",
  },
  {
    id: 20516237,
    name: "Paper Plus Te Awamutu",
  },
  {
    id: 20516217,
    name: "Paper Plus Te Kuiti",
  },
  {
    id: 20516254,
    name: "Paper Plus Te Puke",
  },
  {
    id: 20516246,
    name: "Paper Plus Upper Hutt",
  },
  {
    id: 20516301,
    name: "Paper Plus Waihi",
  },
  {
    id: 20516257,
    name: "Paper Plus Waipukurau",
  },
  {
    id: 20516181,
    name: "Paper Plus Wanaka",
  },
  {
    id: 20516290,
    name: "Paper Plus Wanganui",
  },
  {
    id: 20516283,
    name: "Paper Plus Warkworth",
  },
  {
    id: 19964730,
    name: "Paper Plus Web Store",
  },
  {
    id: 108552064,
    name: "Paper Plus Westcity",
  },
  {
    id: 20516258,
    name: "Paper Plus Whakatane",
  },
  {
    id: 111704531,
    name: "Paper Plus Whangaparaoa",
  },
  {
    id: 20516147,
    name: "Paper Plus Whangarei",
  },
  {
    id: 20516311,
    name: "Paper Plus Whitianga",
  },
  {
    id: 20516182,
    name: "Paper Plus Winton",
  },
];

function useUrlQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Index: React.FC = () => {
  const query = useUrlQuery();
  const token = query.get("token");
  const ident = query.get("ident");
  const identType = query.get("identType");
  const [togglePassword, togglePasswordSet] = useState(false);

  const { handleSubmitUpdateDetails } = useUpdateDetails(token || "");

  const { register, control, handleOnSubmit, setValue, watch, reset, errors } =
    useMemberDetailsUpdateForm({
      emailAddress: identType === "5" ? ident : "",
      mobileNumber: identType === "4" ? ident : "",
      onSubmit: handleSubmitUpdateDetails,
    });

  const isReadOnlyEmailAddress = useMemo(() => {
    return watch().emailAddress !== "" ? true : false;
  }, [watch]);
  const isReadOnlyMobileNumber = useMemo(() => {
    return watch().mobileNumber !== "" ? true : false;
  }, [watch]);

  return (
    <form onSubmit={handleOnSubmit} className="font-sans">
      <h2 className="font-extrabold tracking-wider">Update Details</h2>

      <label>First name * </label>
      <input
        type="text"
        {...register("firstName", { pattern: /^[A-Za-z]+$/i })}
      />
      {errors.firstName && <p>{errors.firstName.message}</p>}

      <label>Last Name * </label>
      <input
        type="text"
        {...register("lastName", { pattern: /^[A-Za-z]+$/i })}
      />
      {errors.lastName && <p>{errors.lastName.message}</p>}

      <div className="input-group">
        <div className="input-field">
          <label>Mobile Number * </label>
          <input
            style={{ backgroundColor: isReadOnlyMobileNumber ? "#bbbbbb" : "" }}
            readOnly={isReadOnlyMobileNumber}
            type="text"
            {...register("mobileNumber", { pattern: /^[0-9]+$/i })}
          />
          {errors.mobileNumber && <p>{errors.mobileNumber.message}</p>}
        </div>
        {/* <div className="input-field">
          <label>Home / Business Phone Number</label>
          <input
            type="text"
            {...register("phoneNumber", { pattern: /^[0-9]+$/i })}
          />
          {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
        </div> */}
      </div>

      <label>Preffered Store * </label>
      <select {...register("preferredStore", { pattern: /^[0-9]+$/i })}>
        <option value="">Please select</option>
        {STORES.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      {errors.preferredStore && <p>{errors.preferredStore.message}</p>}

      <label>Email Address * </label>

      <input
        style={{ backgroundColor: isReadOnlyEmailAddress ? "#bbbbbb" : "" }}
        readOnly={isReadOnlyEmailAddress}
        type="email"
        {...register("emailAddress")}
      />
      {errors.emailAddress && <p>{errors.emailAddress.message}</p>}

      <label>Birthday</label>
      <input placeholder="DD-MM-YYYY" type="date" {...register("birthday")} />
      <div className="w-full">
        <div className="flex justify-between w-full">
          <label>Password * </label>
          <label
            className="font-extralight text-xs"
            onClick={() => togglePasswordSet(!togglePassword)}
          >
            {togglePassword ? `Hide Password` : `Show Password`}
          </label>
        </div>
        <input
          className="w-full"
          type={togglePassword ? "text" : "password"}
          {...register("password")}
        />
        {errors.password && <p>{errors.password.message}</p>}
      </div>

      <label>Confirm Password * </label>
      <input
        type={togglePassword ? "text" : "password"}
        {...register("confirmPassword")}
      />
      {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}

      <div className="form-group">
        <div className="checkbox">
          <div className="Test checkbox">
            <input
              type="checkbox"
              defaultChecked={true}
              value="true"
              id="IsNewsUpdatesEmailsEnabled"
            />
            <span>
              Yes, I am happy to receive special offers, news &amp; reviews
              through email alerts
            </span>
          </div>
          <div className="Test checkbox">
            <input
              type="checkbox"
              defaultChecked={true}
              value="true"
              id="IsTextEnabled"
            />
            <span>Yes, I am happy to receive SMS/text alerts</span>
          </div>

          <div className="px-4 py-2 pb-4 bg-gray-100 mt-6 my-4 rounded-xl">
            <div className="Test checkbox">
              <input
                type="checkbox"
                value="true"
                id="IsBdcTermsAccepted"
                {...register("IsBdcTermsAccepted")}
              />
              <span>
                I Accept the&nbsp;
                <a
                  target="new"
                  href="https://paperplus-uat.solutionists.co.nz/bdc-terms-and-conditions"
                >
                  Terms &amp; Conditions of Membership
                </a>
                *
              </span>
            </div>
            {errors.IsBdcTermsAccepted && (
              <p className="font-semibold pl-6">
                {errors.IsBdcTermsAccepted.message}
              </p>
            )}
            <div className="Test checkbox">
              <input
                type="checkbox"
                value="true"
                id="IsTermsAccepted"
                {...register("IsTermsAccepted")}
              />
              <span>
                I Accept the&nbsp;
                <a target="new" href="/Terms-and-Conditions.aspx">
                  Terms and Conditions of this website
                </a>
                *
              </span>
            </div>
            {errors.IsTermsAccepted && (
              <p className="font-semibold pl-6">
                {errors.IsTermsAccepted.message}
              </p>
            )}
          </div>
        </div>
      </div>
      <input type="submit" />
    </form>
  );
};

export default Index;
