import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

import { updateMemberDetailAPI } from "../../api/members";
import { UpdateDetailsFormTypes } from "../../types/updateDetails";

export const useUpdateDetails = (token: string) => {
  const handleSubmitUpdateDetails = useCallback(
    async (values: UpdateDetailsFormTypes) => {
      try {
        await updateMemberDetailAPI({
          token: token,
          emailAddress: values.emailAddress,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.mobileNumber,
          birthday: values.birthday,
          preferredStore: values.preferredStore,
          flEmail: values.IsNewsUpdatesEmailsEnabled ? 1 : 0,
          flSMS: values.IsTextEnabled ? 1 : 0,
        });
        toast.success("Member update successful!");
      } catch (error) {
        const message = JSON.parse(JSON.stringify(error));
        console.log("test", message);
        // toast.error(message.outcomeUserMessage);
        toast.error("Something went wrong!");
      }
    },
    [updateMemberDetailAPI]
  );

  return {
    handleSubmitUpdateDetails,
  };
};
